import React, { useMemo } from 'react'
import Slider from 'react-slick'
import { Container } from '@shared/components/container'
import { Section } from '@shared/components/section'
import { ScreensizeParams, useScreenSize } from '@shared/hooks/use-screen-size'
import ArrowLeftCircle from '@shared/svg/ArrowLeftCircle.svg'
import ArrowRightCircle from '@shared/svg/ArrowRightCircle.svg'

import { BankCardView } from './BankCardView'
import { defaultBanksList } from './data'
import { BankCard } from './model'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from './style.module.scss'
interface Props {
	banksList?: BankCard[]
}

const getSLidesToShow = (screenSize: ScreensizeParams) => {
	if (screenSize.isExtraMobile) return 1.2
	if (screenSize.isMobile) return 1.4
	if (screenSize.isTablet) return 2.2
	if (screenSize.isDesktop) return 3
	if (screenSize.isLargeDesktop) return 3

	return 2
}

export const View = ({ banksList = defaultBanksList }: Props) => {
	const screenSize = useScreenSize()

	const settings = useMemo(
		() => ({
			className: styles.slider,
			dots: screenSize.isLargeDesktop ? true : false,
			infinite: false,
			speed: 500,
			slidesToShow: getSLidesToShow(screenSize),
			slidesToScroll: 1,
			arrows: screenSize.isLargeDesktop ? true : false,
			prevArrow: <ArrowLeftCircle />,
			nextArrow: <ArrowRightCircle />,
		}),
		[screenSize]
	)

	return (
		<div className={styles.wrapper}>
			<Section>
				<h2 className={styles.title}>Не являемся кредитными брокерами</h2>
				<div className={styles.description}>
					<p>
						Выдаём кредиты без посредников в партнёрстве с банками и другими лицензированными
						кредиторами.
					</p>
					<p>
						Мы вместе с банками и другими финансовыми компаниями создаём доступные кредитные
						продукты. Нам важно, чтобы у наших клиентов были лучшие условия из возможных.
					</p>
				</div>
			</Section>
			<Container className={styles.sliderWrap}>
				<Slider {...settings}>
					{banksList.map((bk) => {
						return <BankCardView key={bk.id} {...bk} />
					})}
				</Slider>
			</Container>
		</div>
	)
}
