import { BankCard } from './model'

export const defaultBanksList: BankCard[] = [
	{
		id: '1',
		title: 'АО «Дальневосточный банк»',
		image: 'dvb.png',
		texts: ['Лицензия Банка России № 843 от 16.10.2020'],
	},
	{
		id: '2',
		title: 'ПАО «Росбанк»',
		image: 'rosBank.png',
		texts: ['Генеральная лицензия №2272 от 28.01.15'],
	},
	{
		id: '3',
		title: 'МКК «Магазин Кредитов»',
		texts: ['ИНH 6678014749', 'ОГРН 1126678009380', 'Дата основания: 2012 год'],
	},
	{
		id: '4',
		title: 'КПК «Фонд Ипотечного Кредитования»',
		texts: ['ИНH 6678102434', 'ОГРН 1196658040677', 'Дата основания: 2019 год'],
	},
]
