import React from 'react'
import { Image } from '@shared/components/image'
import SignOnlyLogo from '@shared/svg/sign-only-logo.svg'

import { BankCard } from './model'

import styles from './style.module.scss'

export const BankCardView = ({ title, image, texts }: BankCard) => {
	return (
		<div className={styles.card}>
			{image ? <Image name={image} /> : <SignOnlyLogo />}
			<h3 className={styles.name}>{title}</h3>
			<ul className={styles.info}>
				{texts.map((text, id) => {
					return <li key={id}>{text}</li>
				})}
			</ul>
		</div>
	)
}
