import React from 'react'
import { ApplicationForms } from '@shared/components/forms/application'
import { scrollToApplicationForm } from '@shared/components/forms/application/cross'
import { Section } from '@shared/components/section'
import { ComfortTerms } from '@shared/components/sections/comfort-terms'
import { Feedback } from '@shared/components/sections/feedback'
import { NotBrockers } from '@shared/components/sections/not-brockers'
import { ProductsLandPages } from '@shared/components/sections/products-land-pages'
import ProductsLandPagesSVG from '@shared/svg/patterns/ProductsLandPages.svg'
import { AboutYoutube } from 'screens/about/sections/about-youtube'
import { Journal } from 'screens/about/sections/journal'
import comfortTermsMaskMob from '../../../../../../public/images/webp/comfortTermsMaskMob.webp'
import comfortTermsMaskTablet from '../../../../../../public/images/webp/comfortTermsMaskTablet.webp'
import comfortTermsMaskLargeDesctop from '../../../../../../public/images/webp/comfortTermsMaskLargeDesctop.webp'
// import { Offers } from '@shared/components/sections/offers'
import { AppSection } from '../AppSection'

import { comfortTerms, journalPointsTabLoan, titleFeedback, youtuVideo } from './data'

import styles from './style.module.scss'
import Image from 'next/image'
import { useSelectDevice } from '@shared/hooks/use-select-device'

export const View = () => (
	<>
		{/*<Section fullWidth className={styles.section}>*/}
		{/*	<h2 className={styles.sectionTitle}>*/}
		{/*		Единое окно для получения любого кредита. По низким ставкам*/}
		{/*	</h2>*/}
		{/* <Offers /> */}
		{/*</Section>*/}
		<div className={styles.sectionComfortTerms}>
			<Image
				alt=''
				loading='lazy'
				className={styles.backgroundImage}
				src={useSelectDevice({
					default: comfortTermsMaskMob,
					tablet: comfortTermsMaskTablet,
					desktop: comfortTermsMaskLargeDesctop,
					largeDesktop: comfortTermsMaskLargeDesctop,
				})}
			/>
			<Section fullWidth>
				<ComfortTerms terms={comfortTerms} handleClick={scrollToApplicationForm} />
			</Section>
		</div>
		<div className={styles.sectionProductsLandPages}>
			<Section fullWidth>
				<ProductsLandPages />
			</Section>
			<ProductsLandPagesSVG />
		</div>
		<Journal sectionClassName={styles.sectionJournal} journalPoints={journalPointsTabLoan} />
		<AboutYoutube sectionClassName={styles.sectionAbout} video={youtuVideo} />
		<Feedback title={titleFeedback} withReviewLinks />
		<div className={styles.section}>
			<NotBrockers />
		</div>
		<div data-id='form-version-2' />
		<ApplicationForms.CrossNew
			processBarText='Вероятность одобрения'
			formTitle='Заявка на кредит'
			loanPercent={9.5}
		/>
		<div className={styles.backgroundBlueAlice}>
			<AppSection />
		</div>
	</>
)
